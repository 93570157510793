import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import {
  getAll,
  getAllCalendarDataWithFilterBO,
  getBySpeakerId,
  getCalendarData,
  getCellDetails,
  getCellDetailsBO,
  getMeetingAttendees,
  getSelectedSpeakerData,
} from '../../../services/apiServices/calendarServices.js';
import ActionTypes from './actionTypes.js';

const getByIdAction = (id) => {
  return async (dispatch) => {
    await getBySpeakerId(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.calendar.GET_ACTION_CALENDAR_DATA_BY_SPEAKERID,
    payload: data,
  };
};
const getAllAction = (date) => {
  return async (dispatch) => {
    await getAll(date).then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.calendar.GETALL_ACTION_CALENDAR_DATA,
    payload: data,
  };
};

const getSelectedSpeakerDataAction = (filter) => {
  return async (dispatch, getState) => {
    const { getSelectedSpeakerDataAction } = getState().calendar.loading;
    if (getSelectedSpeakerDataAction) return;
    dispatch(handleRequestReducer({ getSelectedSpeakerDataAction: true }));
    dispatch(cleanState(true, true));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getSelectedSpeakerData(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            await dispatch(getSelectedSpeakerDataReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getSelectedSpeakerDataReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getSelectedSpeakerDataAction: false }));
    }
  };
};
const getSelectedSpeakerDataReducer = (data) => {
  return {
    type: ActionTypes.calendar.GET_ACTION_CALENDAR_DATA_BY_SPEAKERID,
    payload: data,
  };
};

const getCalendarDataAction = (filter) => {
  return async (dispatch, getState) => {
    const { getCalendarDataAction } = getState().calendar.loading;
    if (getCalendarDataAction) return;
    dispatch(handleRequestReducer({ getCalendarDataAction: true })); // burada request ongoing demek
    dispatch(cleanState(true, true));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getCalendarData(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            await dispatch(getCalendarDataReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getCalendarDataReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getCalendarDataAction: false })); // İşlem tamamlandı
    }
  };
};

const getCalendarDataReducer = (data) => {
  return {
    type: ActionTypes.calendar.GetCalendarData,
    payload: data,
  };
};

const getCellDetailsAction = (data) => {
  return async (dispatch) => {
    dispatch(handleErrorReducer(''));
    dispatch(handleValidationReducer(''));
    await getCellDetails(data).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.isSuccess) {
        await dispatch(getCellDetailsReducer(response));
        dispatch(handleErrorReducer(true));
      } else {
        dispatch(handleErrorReducer(false));
        dispatch(handleValidationReducer(res?.data?.message));
      }
    });
  };
};
const getCellDetailsReducer = (data) => {
  return {
    type: ActionTypes.calendar.GetCellDetails,
    payload: data,
  };
};

const getAllBOCalendarAction = (filter) => {
  return async (dispatch, getState) => {
    const { getCalendarDataAction } = getState().calendar.loading;
    if (getCalendarDataAction) return;
    dispatch(handleRequestReducer({ getCalendarDataAction: true }));
    dispatch(cleanState(true, true));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getAllCalendarDataWithFilterBO(filter);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            await dispatch(getCalendarDataReducer(response));
            await dispatch(handleErrorReducer(reponseStatus.default));
          } else {
            await dispatch(getCalendarDataReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(reponseStatus.fail));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(reponseStatus.fail));
    } finally {
      dispatch(handleRequestReducer({ getCalendarDataAction: false }));
    }
  };
};

const getCellDetailsBOAction = (data) => {
  return async (dispatch) => {
    await getCellDetailsBO(data).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.isSuccess) {
        await dispatch(getCellDetailsReducer(response));
        dispatch(handleErrorReducer(true));
      } else {
        dispatch(handleErrorReducer(false));
        dispatch(handleValidationReducer(res?.data?.message));
      }
    });
  };
};

const getMeetingAttendeesAction = (data) => {
  return async (dispatch) => {
    dispatch(getMeetingAttendeesReducer([]));
    await getMeetingAttendees(data).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.isSuccess) {
        await dispatch(getMeetingAttendeesReducer(response));
      }
    });
  };
};
const getMeetingAttendeesReducer = (data) => {
  return {
    type: ActionTypes.calendar.getMeetingAttendees,
    payload: data,
  };
};

const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.calendar.HandleValidation,
    payload: data,
  };
};
const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.calendar.HandleError,
    payload: data,
  };
};

const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.calendar.HandleRequest,
    payload: data,
  };
};
const cleanListReducer = (payload) => {
  return { type: ActionTypes.calendar.CleanList, payload: payload };
};
const handleResponseError = async (dispatch, error) => {
  if (error?.response?.status === 400) {
    await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  } else {
    await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  }
};
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(cleanListReducer());
    }
  };
};

const calendarActions = {
  getByIdAction,
  getAllAction,
  getCalendarDataAction,
  getSelectedSpeakerDataAction,
  getCellDetailsAction,
  getCellDetailsReducer,
  handleValidationReducer,
  getAllBOCalendarAction,
  getCellDetailsBOAction,
  getMeetingAttendeesAction,
  getMeetingAttendeesReducer,
  cleanState,
};
export default calendarActions;
