import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import { create, getAll, getById, remove, update } from '../../../services/apiServices/faqServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res.data.code === 200) {
        await dispatch(createReducer());
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.faq.CREATE_ACTION_FAQ };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      await dispatch(updateReducer());
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.faq.UPDATE_ACTION_FAQ };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getByIdReducer(response));
    });
  };
};
const getByIdReducer = (data) => {
  return { type: ActionTypes.faq.GETBYID_ACTION_FAQ, payload: { data } };
};
const getAllAction = (lang) => {
  return async (dispatch, getState) => {
    const { loading } = getState().faq;
    if (loading) return;
    dispatch(handleRequestReducer(true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(lang);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleRequestReducer(false));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleRequestReducer(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.faq.GETALL_ACTION_FAQ, payload: { data } };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.faq.HandleRequest,
    payload: data,
  };
};

const faqGroupActions = {
  createAction,
  createReducer,
  updateAction,
  updateReducer,
  removeAction,
  getByIdAction,
  getByIdReducer,
  getAllAction,
  getAllReducer,
};
export default faqGroupActions;
