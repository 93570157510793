import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import { create, getAll, getById, remove, update } from '../../../services/apiServices/branchFreezeServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleErrorReducer(''));
    await create(createData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(createReducer());
        await dispatch(handleErrorReducer(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.branchFreeze.Create };
};
const updateAction = (updateData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleErrorReducer(''));
    await update(updateData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(updateReducer());
        await dispatch(handleErrorReducer(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.branchFreeze.Update };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.branchFreeze.GetById,
    payload: data,
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.branchFreeze.GetAll,
    payload: { data },
  };
};

const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.branchFreeze.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.branchFreeze.HandleValidation,
    payload: data,
  };
};
const cleanListReducer = (payload) => {
  return { type: ActionTypes.branchFreeze.CleanList, payload: payload };
};
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      // burası modal ve submit buton için önemli
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      // default tüm state default hale gelir.
      dispatch(cleanListReducer());
    }
  };
};
const branchFreezeActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  cleanState,
};
export default branchFreezeActions;
