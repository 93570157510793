const auth = {
  SIGNUP_ACTION: 'signup_action',
  LOGIN_ACTION: 'login_action',
  LOADING_TOGGLE_ACTION: 'loading_toggle_action',
  LOGOUT_ACTION: 'logout_action',
  REFRESH_TOKEN: 'refresh_token',
  OAUTH_ACTION: 'oauth_action',
  HANDLE_VALIDATION_AUTH: 'handle_validation_auth',
  HANDLE_ERROR_AUTH: 'handle_error_auth',
};
const lang = {
  LANGUAGE_CHOSEN: 'language_chosen',
  SET_FLAG: 'set_flag',
};
const screen = {
  SCREEN_SIZE_CHECKED: 'screen_size_checked',
  SIZE_SET: 'size_set',
};
const companyForm = {
  Create: 'create_action_company_form_action',
  Update: 'update_action_company_form_action',
  GetById: 'getbyid_acti̇on_company_form_action',
  GetAll: 'getall_action_company_form_action',
  HandleError: 'handle_error_company_form_action',
  HandleValidation: 'handle_validation_company_form_action',
  HandleRequest: 'handle_request_company_form_action',
  CleanList: 'clean_list_company_form_action',
};
const career = {
  CREATE_ACTION_CAREER: 'create_action_career',
  UPDATE_ACTION_CAREER: 'update_action_career',
  GETBYID_ACTION_CAREER: 'getbyid_action_career',
  GETALL_ACTION_CAREER: 'getall_action_career',
  HANDLE_ERROR_CAREER: 'handle_error_career',
  HANDLE_VALIDATION_CAREER: 'handle_validation_career',
};
const faq = {
  CREATE_ACTION_FAQ: 'create_acti̇on_faq',
  UPDATE_ACTION_FAQ: 'update_acti̇on_faq',
  GETBYID_ACTION_FAQ: 'getbyid_acti̇on_faq',
  GETALL_ACTION_FAQ: 'getall_acti̇on_faq',
  HandleRequest: 'handle_request_faq',
};
const faqGroup = {
  CREATE_ACTION_FAQGROUP: 'create_acti̇on_faqgroup',
  UPDATE_ACTION_FAQGROUP: 'update_acti̇on_faqgroup',
  GETBYID_ACTION_FAQGROUP: 'getbyid_acti̇on_faqgroup',
  GETALL_ACTION_FAQGROUP: 'getall_acti̇on_faqgroup',
  HandleRequest: 'handle_request_faq_group',
};
const user = {
  GetAll: 'getall_action_user_action',
  GetById: 'getbyid_action_user_action',
  Update: 'update_action_user_action',
  SelfUpdate: 'self_update_action_user_action',
  HandleError: 'handle_error_user_action',
  HandleValidation: 'handle_validation_user_action',
  HandleRequest: 'handle_request_user_action',
};
const staticData = {
  GETALL_STATICDATA: 'getall_staticdata',
};
const speaker = {
  CREATE_ACTION_SPEAKER: 'create_acti̇on_speaker',
  UPDATE_ACTION_SPEAKER: 'update_acti̇on_speaker',
  GETBYID_ACTION_SPEAKER: 'getbyid_acti̇on_speaker',
  GETALL_ACTION_SPEAKER: 'getall_acti̇on_speaker',
  GETFILTRED_ACTION_SPEAKER: 'getfiltred_acti̇on_speaker',
  PAGINATE_SPEAKER: 'paginate_speaker',
  HANDLE_ERROR_SPEAKER: 'handle_error_speaker',
  HANDLE_VALIDATION_SPEAKER: 'handle_validation_speaker',
  SPEAKER_PAYMENT_SPEAKER: 'speaker_payment_speaker',
  GetSelectedMentor: 'get_selected_mentor_speaker_action',
  HandleRequest: 'handle_request_speaker',
  CleanList: 'clean_list_speaker_action',
};
const onboard = {
  ONBOARD_CHOSEN: 'onboard_chosen',
};
const couponCodes = {
  CREATE_ACTION_COUPONCODES: 'create_action_couponcodes',
  UPDATE_ACTION_COUPONCODES: 'update_action_couponcodes',
  GETALL_ACTION_COUPONCODES: 'getall_action_couponcodes',
  GETBYNAME_ACTION_COUPONCODES: 'getbyname_action_couponcodes',
  GetById: 'getbyid_acti̇on_couponcodes',
  HANDLE_ERROR_COUPONCODES: 'handle_error_couponcodes',
  HANDLE_VALIDATION_COUPONCODES: 'handle_validation_couponcodes',
};
const timesPerWeek = {
  CREATE_ACTION_TIMESPERWEEK: 'create_action_timesperweek',
  UPDATE_ACTION_TIMESPERWEEK: 'update_action_timesperweek',
  GETALL_ACTION_TIMESPERWEEK: 'getall_action_timesperweek',
  GETBYID_ACTION_TIMESPERWEEK: 'getbyid_action_timesperweek',
  HANDLE_ERROR_TIMESPERWEEK: 'handle_error_timesperweek',
};
const sessionPackage = {
  CREATE_ACTION_SESSIONPACKAGE: 'create_action_sessionpackage',
  UPDATE_ACTION_SESSIONPACKAGE: 'update_action_sessionpackage',
  GETALL_ACTION_SESSIONPACKAGE: 'getall_action_sessionpackage',
  GETBYID_ACTION_SESSIONPACKAGE: 'getbyid_action_sessionpackage',
  HANDLE_ERROR_SESSIONPACKAGE: 'handle_error_sessionpackage',
  HandleRequest: 'handle_request_session_package',
};
const zone = {
  CREATE_ACTION_ZONE: 'create_action_zone',
  UPDATE_ACTION_ZONE: 'update_action_zone',
  GETBYID_ACTION_ZONE: 'getbyid_action_zone',
  GETALL_ACTION_ZONE: 'getall_action_zone',
  HANDLE_ERROR_ZONE: 'handle_error_zone',
};
const attendee = {
  CREATE_ACTION_ATTENDEE: 'create_action_attendee',
  UPDATE_ACTION_ATTENDEE: 'update_action_attendee',
  GETBYID_ACTION_ATTENDEE: 'getbyid_action_attendee',
  GETALL_ACTION_ATTENDEE: 'getall_action_attendee',
  GETFILTRED_ACTION_ATTENDEE: 'getfiltred_acti̇on_attendee',
  GET_REF_CODE_ATTENDEE: 'get_ref_code_attendee',
  HANDLE_VALIDATION_ATTENDEE: 'handle_validation_attendee',
  HANDLE_ERROR_ATTENDEE: 'handle_error_attendee',
  SEARCH_ATTENDEE: 'get_attendee_by_keyword_action',
  GetNotesByMeetingAttendee: 'get_notes_by_meeting_attendee_action',
  ChangeLevel: 'change_level_attendee_action',
  CleanList: 'clean_list_attendee_action',
};
const attendeeFreeze = {
  CREATE_ACTION_ATTENDEEFREEZE: 'create_action_attendeefreeze',
  UPDATE_ACTION_ATTENDEEFREEZE: 'update_action_attendeefreeze',
  GETBYID_ACTION_ATTENDEEFREEZE: 'getbyid_action_attendeefreeze',
  GETALL_ACTION_ATTENDEEFREEZE: 'getall_action_attendeefreeze',
};
const branchFreeze = {
  Create: 'create_branchfreeze_action',
  Update: 'update_branchfreeze_action',
  GetById: 'getbyid_branchfreeze_action',
  GetAll: 'getall_branchfreeze_action',
  HandleError: 'handle_error_branchFreeze_action',
  HandleValidation: 'handle_validation_branchFreeze_action',
  HandleRequest: 'handle_request_branchFreeze_action',
  CleanList: 'clean_list_branchFreeze_action',
};
const manager = {
  CREATE_ACTION_MANAGER: 'create_action_manager',
  UPDATE_ACTION_MANAGER: 'update_action_manager',
  GETBYID_ACTION_MANAGER: 'getbyid_action_manager',
  GETALL_ACTION_MANAGER: 'getall_action_manager',
  HANDLE_VALIDATION_MANAGER: 'handle_validation_manager',
  HANDLE_ERROR_MANAGER: 'handle_error_manager',
};
const location = {
  CREATE_ACTION_LOCATION: 'create_action_location',
  UPDATE_ACTION_LOCATION: 'update_action_location',
  GETBYID_ACTION_LOCATION: 'getbyid_action_location',
  GETALL_ACTION_LOCATION: 'getall_action_location',
  GET_BY_USER_AREA_LOCATION: 'get_by_user_area_location',
  HANDLE_ERROR_LOCATION: 'handle_error_location',
  HANDLE_VALIDATION_LOCATION: 'handle_validation_location',
  HandleRequest: 'handle_request_location',
};
const ageInterval = {
  CREATE_ACTION_AGEINTERVAL: 'create_action_ageInterval',
  UPDATE_ACTION_AGEINTERVAL: 'update_action_ageInterval',
  GETBYID_ACTION_AGEINTERVAL: 'getbyid_action_ageInterval',
  GETALL_ACTION_AGEINTERVAL: 'getall_action_ageInterval',
  HANDLE_ERROR_AGEINTERVAL: 'handle_error_ageInterval',
};
const timesPerMonth = {
  CREATE_ACTION_TIMESPERMONTH: 'create_action_timesPerMonth',
  UPDATE_ACTION_TIMESPERMONTH: 'update_action_timesPerMonth',
  GETBYID_ACTION_TIMESPERMONTH: 'getbyid_action_timesPerMonth',
  GETALL_ACTION_TIMESPERMONTH: 'getall_action_timesPerMonth',
  HANDLE_ERROR_TIMESPERMONTH: 'handle_error_timesPerMonth',
};
const sessionCategory = {
  CREATE_ACTION_SESSIONCATEGORY: 'create_action_sessionCategory',
  UPDATE_ACTION_SESSIONCATEGORY: 'update_action_sessionCategory',
  GETBYID_ACTION_SESSIONCATEGORY: 'getbyid_action_sessionCategory',
  GETALL_ACTION_SESSIONCATEGORY: 'getall_action_sessionCategory',
  HANDLE_ERROR_SESSIONCATEGORY: 'handle_error_sessionCategory',
};
const meetingSchema = {
  Create: 'create_meetingSchema_action',
  Update: 'update_meetingSchema_action',
  GetById: 'getbyid_meetingSchema_action',
  GetAll: 'getall_meetingSchema_action',
  GETALL_ACTION_MEETINGSCHEDULE: 'getall_meetingSchecule_action',
  GETALL_SESSION_COUNT_ACTION_MEETINGSCHEDULE: 'getall_session_count_action_meetingSchecule',
  GET_SPEAKER_DROPDOWN_LIST_ACTION: 'get_speaker_dropdown_list_meetingSchema_action',
  GETALL_ACTION_MEETING_BY_SPEAKER: 'getall_action_meeting_by_speaker',
  GetMeetingSpeakerSchedule: 'get_meeting_speaker_schedule_action',
  GET_MEETING_ATTENDEES: 'get_meeting_attendees_meetingSchema_action',
  ATTENDANCE_CHECK: 'attendance_check_meetingSchema_action',
  SessionHistory: 'attendee_sesssion_history_action',
  HandleError: 'handle_error_meetingSchema_action',
  HandleValidation: 'handle_validation_meetingSchema_action',
  HandleRequest: 'handle_request_meetingSchema_action',
  CleanList: 'clean_list_meetingSchema_action',
};
const order = {
  CREATE_ACTION_ORDER: 'create_action_order',
  UpdateOrder: 'update_order_action',
  UPDATE_ACTION_ORDER_ITEM: 'update_action_order_item',
  GETBYID_ACTION_ORDER: 'getbyid_action_order',
  GETALL_ACTION_ORDER: 'getall_action_order',
  HANDLE_ERROR_ORDER: 'handle_error_order',
  HANDLE_VALIDATION_ORDER: 'handle_validation_order',
  EFT_CONTROL_ACTION_ORDER: 'eft_control_action_order',
  GET_MYSUBSCRIPTION_PERIOD: 'get_mysubscription_period',
  GET_ORDERS: 'get_orders',
  GET_BASKET_ITEMS: 'get_basket_items',
  GET_EARNED_FREESESSIONS: 'get_earned_freesessions',
  GET_GIFT_SESSIONS: 'get_gift_sessions_action',
  SEND_GIFT_SESSION_TO_FRIEND: 'send_gift_session_to_friend_action',
  REEDEM_GIFT_CODE: 'reedem_gift_code_action',
  CANCEL_GIFT: 'cancel_gift_action',
  SELECT_SESSION: 'select_session_action',
  SET_CALENDAR_TABLE: 'set_calendar_table_action',
  ORDER_SET_DATE: 'order_set_date_action',
  GetMeetingsByOrder: 'get_meetings_by_order_action',
  GetPayments: 'get_payments_action',
  GetOrderDetails: 'get_order_details_action',
  GetOrderItemDetails: 'get_order_item_details_action',
  OrderItemUpdate: 'order_item_update_action',
  TodaysTrialList: 'todays_trials_list_action',
  UpcomingTrialList: 'upcoming_trials_list_action',
  RenewOrderItemCalculation: 'renew_order_item_calculation_action',
  RenewOrderItem: 'renew_order_item_action',
  DemoCreation: 'demo_creation_action',
  CalculationRemainingSessionFee: 'calculation_remaining_sessionFee_action',
  GetOrdersByAttendeeId: 'get_ordersby_attendeeid_action',
  HandleRequest: 'handle_request_order',
  CleanList: 'clean_list_order_action',
};
const becomeAPartner = {
  CREATE_ACTION_BECOMEAPARTNER: 'create_acti̇on_becomeapartner',
  UPDATE_ACTION_BECOMEAPARTNER: 'update_acti̇on_becomeapartner',
  GETBYID_ACTION_BECOMEAPARTNER: 'getbyid_acti̇on_becomeapartner',
  GETALL_ACTION_BECOMEAPARTNER: 'getall_acti̇on_becomeapartner',
};
const banner = {
  Create: 'create_action_banner',
  Update: 'update_action_banner',
  GetById: 'getbyid_acti̇on_banner',
  GetAll: 'getall_action_banner',
  GetActiveBannerByZone: 'get_active_banner_by_zone_action',
  HandleError: 'handle_error_banner',
  HandleValidation: 'handle_validation_banner',
  HandleRequest: 'handle_request_banner',
  CleanList: 'clean_list_banner_action',
};
const userPackage = {
  CREATE_ACTION_USERPACKAGE: 'create_action_userpackage',
  COMPLETE_ACTION_USERPACKAGE: 'complete_action_userpackage',
};
const calendar = {
  GET_ACTION_CALENDAR_DATA_BY_SPEAKERID: 'get_action_calendar_data_by_speakerid',
  GETALL_ACTION_CALENDAR_DATA: 'getall_action_calendar_data',
  GetCalendarData: 'get_calendar_data_calendar_action',
  GetCellDetails: 'get_cell_details_calendar_action',
  getMeetingAttendees: 'get_meetingAttendees_calendar_action',
  HandleError: 'handle_error_calendar_action',
  HandleValidation: 'handle_validation_calendar_action',
  HandleRequest: 'handle_request_calendar_action',
  CleanList: 'clean_list_calendar_action',
};
const speakerAvailability = {
  Create: 'create_speaker_availability_action',
  Update: 'update_speaker_availability_action',
  GetAll: 'getall_speaker_availability_action',
  HandleError: 'handle_error_speaker_availability_action',
  GetSelectedSpeakerDates: 'get_selected_speaker_dates_speaker_availability_actiony',
  HandleValidation: 'handle_validation_speaker_availability_action',
};

const classroom = {
  CommitMessage: 'commit_message_classroom_action',
  GetPrivateRoomMembers: 'get_private_members_classroom_action',
  GetPrivateMessages: 'get_private_message_classroom_action',
  GetGroupMessages: 'get_group_message_classroom_action',
  GetGroupRoomMembers: 'get_group_members_classroom_action',
  HandleError: 'handle_error_classroom_action',
  HandleValidation: 'handle_validation_classroom_action',
  CleanList: 'clean_list_classroom_action',
};
const subscription = {
  GetAll: 'get_all_subscription_action',
  HandleError: 'handle_error_subscription_action',
  HandleValidation: 'handle_validation_subscription_action',
  HandleRequest: 'handle_request_subscription_action',
  CleanList: 'clean_list_subscription_action',
};
const userOperationClaim = {
  ADD_CLAIM_TO_USER_ACTION: 'add_calim_to_user_action',
  HANDLE_ERROR_USER_OPERATION_CLAIM_ACTION: 'handle_error_user_operation_claim_action',
  HANDLE_VALIDATION_USER_OPERATION_CLAIM_ACTION: 'handle_validation_user_operation_claim_action',
};
const payment = {
  GET_INSTALLMENT_ACTION: 'get_installment_action',
  ON_PAYMENT_ACTION: 'on_payment_action',
  PAYMENT_CALLBACK_ACTION: 'payment_callback_action',
  HANDLE_ERROR_PAYMENT: 'handle_error_payment',
  HANDLE_VALIDATION_PAYMENT: 'handle_validation_payment',
  RESET_STATE: 'reset_state',
  RESULT_PAYMENT: 'result_payment',
  HandleRequest: 'handle_request_payment',
};
const meetingAttendee = {
  HandleError: 'handle_error_meetingAttendee_action',
  HandleValidation: 'handle_validation_meetingAttendee_action',
};

const filter = {
  HOLD_FILTER: 'hold_filter',
  HOLD_CREATE_DATA: 'hold_create_data',
  HOLD_FOR_BADGE: 'hold_for_badge',
  RESET_STATE: 'reset_state',
};

const preference = {
  CURRENCY_CHOSEN: 'currency_chosen_action',
  GET_CURRENCY_OPTION: 'get_currency_option_action',
  GET_LEVEL_GROUP: 'get_level_group_action',
  HandleRequest: 'handle_request_preference',
};
const resetStore = {
  RESET_STORE: 'reset_store_action',
  CLEAN_LOCATION: 'clean_location_action',
};
const feedback = {
  Create: 'create_feedback_action',
  GetAll: 'get_all_feedback_action',
  HandleError: 'handle_error_feedback_action',
  HandleValidation: 'handle_validation_feedback_action',
};
const speakerEffort = {
  GetDetailsBySpeaker: 'get_details_by_speaker_speaker_effort_action',
  GetAll: 'get_all_speaker_effort_action',
  HandleError: 'handle_error_speaker_effort_action',
  HandleValidation: 'handle_validation_speaker_effort_action',
  CleanList: 'clean_list_speaker_effort_action',
};
const lead = {
  Create: 'create_lead_action',
  Update: 'update_lead_action',
  GetById: 'getbyid_lead_action',
  GetAll: 'get_all_lead_action',
  HandleError: 'handle_error_lead',
  HandleValidation: 'handle_validation_lead',
  CleanList: 'clean_list_lead_action',
};
const price = {
  CalculatedPrices: 'calculated_prices_action',
  HandleError: 'handle_error_price_action',
  HandleValidation: 'handle_validation_price_action',
  HandleRequest: 'handle_request_price_action',
};
const company = {
  Create: 'create_company_action',
  Update: 'update_company_action',
  Delete: 'delete_company_action',
  GetById: 'getById_company_action',
  GetAll: 'getAll_company_action',
  GetCompanyAttendeesDropdown: 'getCompanyAttendeesDropdown_company_action',
  GetCompanyAttendees: 'getCompanyAttendees_company_action',
  GetCompanyManagers: 'getCompanyManagers_company_action',
  GetCompanyOrders: 'getCompanyOrders_company_action',
  CompanyReporting: 'company_reporting_action',
  HandleError: 'handle_error_company_action',
  HandleValidation: 'handle_validation_company_action',
  CleanList: 'clean_list_company_action',
};
const campaign = {
  Create: 'create_action_campaign',
  Update: 'update_action_campaign',
  GetById: 'getbyid_acti̇on_campaign',
  GetAll: 'getall_action_campaign',
  GetActiveCampaigns: 'get_active_campaigns_action',
  GetSelectedCampaignDetails: 'get_selected_campaign_details_action',
  GetDashbordBanner: 'get_dashboard_banner_action',
  HandleError: 'handle_error_campaign',
  HandleValidation: 'handle_validation_campaign',
  CleanList: 'clean_list_campaign_action',
};
const reporting = {
  GetAll: 'getall_action_reporting',
  HandleError: 'handle_error_reporting_action',
  HandleValidation: 'handle_validation_reporting_action',
  HandleRequest: 'handle_request_reporting_action',
  CleanList: 'clean_list_reporting_action',
};
const wheelEvent = {
  CreateWheelParticipation: 'create_wheel_participation_wheel_event_action',
  ReedemReward: 'reedem_reward_wheel_event_action',
  GetActiveWheel: 'get_active_wheel_event_action',
  HandleError: 'handle_error_wheel_event_action',
  HandleValidation: 'handle_validation_wheel_event_action',
  HandleRequest: 'handle_request_wheel_event_action',
  CleanList: 'clean_list_wheel_event_action',
};
const meeting = {
  Create: 'create_meeting_action',
  Update: 'update_meeting_action',
  GetMeetingsBySchemaId: 'get_meetings_by_schema_id_meeting_action',
  ChangePaymentStatus: 'change_payment_status_meeting_action',
  CancelPenalty: 'cancel_penalty_meeting_action',
  HandleError: 'handle_error_meeting_action',
  HandleValidation: 'handle_validation_meeting_action',
  HandleRequest: 'handle_request_meeting_action',
  CleanList: 'clean_list_meeting_action',
};

const ActionTypes = {
  auth,
  screen,
  lang,
  companyForm,
  career,
  faq,
  faqGroup,
  user,
  staticData,
  speaker,
  onboard,
  couponCodes,
  timesPerWeek,
  sessionPackage,
  zone,
  attendee,
  attendeeFreeze,
  branchFreeze,
  manager,
  location,
  ageInterval,
  timesPerMonth,
  sessionCategory,
  meetingSchema,
  order,
  becomeAPartner,
  banner,
  userPackage,
  calendar,
  speakerAvailability,
  classroom,
  subscription,
  userOperationClaim,
  payment,
  meetingAttendee,
  filter,
  resetStore,
  preference,
  feedback,
  speakerEffort,
  lead,
  price,
  company,
  campaign,
  reporting,
  wheelEvent,
  meeting,
};

export default ActionTypes;
