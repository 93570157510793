import formatDates from '../../../utils/formatDates.js';

import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import {
  changeLevel,
  create,
  getAll,
  getAttendeeBySearchKeyword,
  getById,
  getNotesByMeetingAttendee,
  getRefCode,
  remove,
  sendRefCodeEmail,
  update,
} from '../../../services/apiServices/attendeeServices.js';
import ActionTypes from './actionTypes.js';
import Actions from './index.js';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          const object = { userId: res?.data?.data?.userId, phoneNumber: res?.data?.data?.phone };
          await dispatch(Actions.userActions.confirmPhoneNumberAction(object));
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const createReducer = () => {
  return { type: ActionTypes.attendee.CREATE_ACTION_ATTENDEE };
};

const updateAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData, id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const updateReducer = () => {
  return { type: ActionTypes.attendee.UPDATE_ACTION_ATTENDEE };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.attendee.GETBYID_ACTION_ATTENDEE,
    payload: { data },
  };
};
const getAllAction = (requestType) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(requestType);
        let response = res?.data?.data;
        if (response?.length > 0) {
          response = formatDates(response);
        }
        await dispatch(getAllReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.attendee.GETALL_ACTION_ATTENDEE,
    payload: { data },
  };
};

const getNotesByMeetingAttendeeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getNotesByMeetingAttendee(id);
        let response = res?.data?.data;
        await dispatch(getNotesByMeetingAttendeeReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getNotesByMeetingAttendeeReducer = (data) => {
  return {
    type: ActionTypes.attendee.GetNotesByMeetingAttendee,
    payload: { data },
  };
};

const searchAttendeeAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAttendeeBySearchKeyword(data);
        let response = res?.data?.data;
        await dispatch(searchAttendeeReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const searchAttendeeReducer = (data) => {
  return {
    type: ActionTypes.attendee.SEARCH_ATTENDEE,
    payload: data,
  };
};

const getRefCodeAction = () => {
  return async (dispatch) => {
    await getRefCode().then(async (res) => {
      let response = res.data.data;
      await dispatch(getRefCodeReducer(response));
    });
  };
};
const getRefCodeReducer = (data) => {
  return {
    type: ActionTypes.attendee.GET_REF_CODE_ATTENDEE,
    payload: { data },
  };
};
const sendRefCodeEmailAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await sendRefCodeEmail(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const changeLevelAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await changeLevel(data)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.attendee.HANDLE_VALIDATION_ATTENDEE,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.attendee.HANDLE_ERROR_ATTENDEE,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.attendee.CleanList };
};

const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(cleanListReducer());
    }
  };
};

const attendeeActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  searchAttendeeAction,
  getRefCodeAction,
  sendRefCodeEmailAction,
  getNotesByMeetingAttendeeAction,
  cleanState,
  changeLevelAction,
};
export default attendeeActions;
