import { create, getAll, getById, remove, update } from '../../../services/apiServices/becomeApartnerServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      await dispatch(createReducer());
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.becomeAPartner.CREATE_ACTION_BECOMEAPARTNER };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      await dispatch(updateReducer());
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.becomeAPartner.UPDATE_ACTION_BECOMEAPARTNER };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.becomeAPartner.GETBYID_ACTION_BECOMEAPARTNER,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.becomeAPartner.GETALL_ACTION_BECOMEAPARTNER,
    payload: { data },
  };
};

const becomeAPartnerActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
};
export default becomeAPartnerActions;
