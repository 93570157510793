import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../constants/Enum.jsx';
import { create, getAll, getById, remove, update } from '../../../services/apiServices/companyFormServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const createReducer = () => {
  return { type: ActionTypes.companyForm.Create };
};
const updateAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.companyForm.Update };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(handleErrorReducer(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleErrorReducer(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.companyForm.GetById,
    payload: data,
  };
};
const getAllAction = (requestType) => {
  return async (dispatch, getState) => {
    const { getAllAction } = getState().meeting.loading;
    if (getAllAction) return;
    dispatch(handleRequestReducer({ getAllAction: true }));
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    const maxRetries = config.RequestRule.retryCount;
    const delay = config.RequestRule.delay;
    let retriesLeft = maxRetries;
    try {
      while (retriesLeft > 0) {
        if (retriesLeft !== maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        try {
          const res = await getAll(requestType);
          let response = res?.data?.data;
          let isSuccess = res?.data?.isSuccess;
          if (isSuccess) {
            await dispatch(getAllReducer(response));
            await dispatch(handleErrorReducer(''));
          } else {
            await dispatch(getAllReducer([]));
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleErrorReducer(false));
          }
          break;
        } catch (error) {
          retriesLeft--;
          if (retriesLeft === 0) throw error;
        }
      }
    } catch (error) {
      await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
      await dispatch(handleErrorReducer(false));
    } finally {
      dispatch(handleRequestReducer({ getAllAction: false }));
    }
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.companyForm.GetAll,
    payload: data,
  };
};

const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.companyForm.HandleValidation,
    payload: data,
  };
};
const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.companyForm.HandleError,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.companyForm.CleanList };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.companyForm.HandleRequest,
    payload: data,
  };
};
const handleResponseError = async (dispatch, error) => {
  if (error?.response?.status === 400) {
    await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  } else {
    await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  }
};
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(cleanListReducer());
    }
  };
};
const companyFormActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  cleanState,
};
export default companyFormActions;
